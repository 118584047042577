import React, { Component } from 'react';
import './App.css';
import logo from "./assets/images/Logo.png";
import heroImage from "./assets/images/make-all-the-right.png";
import Tabletop from 'tabletop';

class App extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    Tabletop.init({
      key: "1_keEJGO2Msex3PGQbhbnj0-_6LkuonOfLAMajLnK6wo",
      callback: (googleData) => {
        this.setState({
          data: googleData,
        });
      },
      simpleSheet: true,
    });
  }
  render() {
    const { data } = this.state;

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const d = new Date();
  let month = monthNames[d.getMonth()];
  let year = new Date().getFullYear();

console.log(data[0]);
  
    return (
      <div>
        <div className="header">
          <img src={logo} alt="savings account advisors" className="img-responsive" />
        </div>
        <div className="mainSection">
          <img src={heroImage} className="img-responsive hero-image" alt="make all the right money moves" />
          <div className="content">
            <h2>
              {data.map((content) => {
                return (
                  content.paragraph
                )
              })}
            </h2>
            <h1> The Best Savings Accounts of {month} {year}</h1>
            <div className="ratesSection">
              <table>
                  <thead>
                <tr>
                  <th>Bank</th>
                  <th>APY</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {data.map((obj) => {
                  return (
                    <tr key={obj.key}>
                      <td className="text-left">
                        <a href={obj.buttonHref}><img
                          src={obj.img}
                          className="img-responsive bank"
                          img
                          alt={obj.bank}
                        ></img></a>
                        <p>{obj.contentBelowApy} </p>
                      </td>
                      <td>
                        <h3>{obj.api}</h3>
                      </td>
                      <td>
                        <a href={obj.buttonHref} className="button" alt="bank url call to action">
                          Open Account
                        </a>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="footer">
          <p>The rates and promotions displayed are gathered from various financial institutions and may not reflect all of the products available in your region. Although we strive to provide the most accurate data possible, we cannot guarantee its accuracy. The content displayed is for general information purposes only; always verify account details and availability with the financial institution before opening an account. We are not affiliated with the financial institutions listed here.</p>
        </div>
      </div>
    );
  }
}

export default App;